::-webkit-scrollbar {
  background-color: cadetblue;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: black;
  width: 5px;
  height: 10px;
}
::-webkit-scrollbar:horizontal{
  height: 5px;
}
a:-webkit-any-link {
  color: black;
  text-decoration: none;
}
.cut-price {
  position: relative;
}
.cut-price::after {
  content: "";
  width: 100%;
  height: 1px;
  background: black;
  position: absolute;
  bottom: 10px;
  left: 0;
}

/* .card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  font-family: arial;
  margin-left: 20px;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
.card-flash {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 250px;
  font-family: arial;
  margin-left: 50px;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
} */

.card button,.card-flash button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  border-radius: 10px;
}

.card button:hover,.card-flash button:hover {
  opacity: 0.7;
}
center {
  background-color: "#f9f7f3" !important;
}

/* Common styles for both .card and .card-flash */
.card, .card-flash {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  font-family: arial;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.price {
  color: grey;
  font-size: 22px;
}

.card button, .card-flash button {
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
  border-radius: 10px;
}

.card button:hover, .card-flash button:hover {
  opacity: 0.7;
}

/* Styles specific to .card */
.card {
  max-width: 300px;
  margin-left: 20px;
}

/* Styles specific to .card-flash */
.card-flash {
  max-width: 250px;
  margin-left: 50px;
  width: 100%;
}
.card-flash:hover{
  transform: translateY(-10px);
}

/* Responsive styles */
@media only screen and (max-width: 600px) {
  .card-flash {
    max-width: 100%;
    margin-left: 0;
  }
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-field{
  margin-left: 350px;
  margin-top: 100px;
  font-family: 'Ubuntu', sans-serif;
  
}
@media (max-width: 600px){
  .form-field{
    margin-left: 50px
  }
}

.heading-field{
  font-family: 'Ubuntu', sans-serif;
}

.input-box{
  width: 80%;
  position: relative;
  cursor: text;
  font-size: 14px;
  line-height: 20px;
  padding: 0 16px;
  height: 48px;
  background-color: #fff;
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  color: rgb(35, 38, 59);
  box-shadow: inset 0 1px 4px 0 rgb(119 122 175 / 30%);
  overflow: hidden;
  transition: all 100ms ease-in-out;
  :focus {
      border-color: #3c4fe0;
      box-shadow: 0 1px 0 0 rgb(35 38 59 / 5%);
  }

}

.input-field{
  margin-bottom: 30px;
}

.label-text{
  font-size: 1rem;
}

.register-button{  
  display: inline-block;
  outline: 0;
  cursor: pointer;
  border: none;
  padding: 0 56px;
  height: 45px;
  line-height: 45px;
  border-radius: 7px;
  background-color: #0070f3;
  color: white;
  font-weight: 400;
  font-size: 16px;
  box-shadow: 0 4px 14px 0 rgb(0 118 255 / 39%);
  transition: background-color 0.2s ease,color 0.2s ease,box-shadow 0.2s ease;  
}

.inventory-field{
  margin-left: 300px;
  margin-top: 40px;
}